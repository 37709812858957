import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Layout from './components/layout/layout'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import config from "../src/config";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import RotateLoader from "react-spinners/ClipLoader";
import { getToken } from "./axios";

class APP extends React.Component {
  state = { isloading: true, accessToken: null }

  constructor(props) {
    super(props)
    this.getAuthorizationToken();
  }

  componentWillMount() {

  }

  async getAuthorizationToken() {
    var token = "";
    await getToken().then((response) => {
      token = response.data.token_type + ' ' + response.data.access_token;
      this.setState({ accessToken: token })
    })
    return token;
  }

  render() {
    let client = new ApolloClient({
      uri: config.gcms.uri,
      request: (operation) => {
        operation.setContext({
          headers: {
            authorization: this.state.accessToken
          }
        })
      }
    })

    var treeList = [];
    var fullTreeList = [];
    var folderPathArrayIsListed = [];
    var folderPathArray = [];
    var jsonFolderPathArrayObject = {
      path: "",
      id: "",
    };


    function buildfolderPathsIsListed(item, jsonFolderPathArrayObject) {
      jsonFolderPathArrayObject = {
        path: jsonFolderPathArrayObject.path + "/" + item.data.title.iv,
        id: item.id
      }

      folderPathArrayIsListed.push(jsonFolderPathArrayObject);
      if (Array.isArray(item.children) && item.data.isListed.iv) {
        jsonFolderPathArrayObject = {
          path: item.data.title.iv !== 'index' ? jsonFolderPathArrayObject.path : '/',
          id: item.id
        }
        item.children.map(item => buildfolderPathsIsListed(item, jsonFolderPathArrayObject))
      }
    }

    function buildArrayPaths(item, jsonFolderPathArrayObject) {
      jsonFolderPathArrayObject = {
        path: jsonFolderPathArrayObject.path + "/" + item.data.title.iv,
        id: item.id
      }
      folderPathArray.push(jsonFolderPathArrayObject);
      if (Array.isArray(item.children)) {
        jsonFolderPathArrayObject = {
          path: item.data.title.iv !== 'index' ? jsonFolderPathArrayObject.path : '/',
          id: item.id
        }
        item.children.map(item => buildArrayPaths(item, jsonFolderPathArrayObject))
      }
    }
    return (
      <div>
        {this.state.accessToken === null ? <div className="centered">
          <div >
            <RotateLoader
              color={"#16728a"}
              loading={true}
            />
          </div>

          {/* <div>Loading...</div> */}
        </div> :
          <ApolloProvider client={client}>
            <Query
              query={gql`
    {
      queryDocContents{
        id
        data {
          parent {
            iv {
              data {
                title {
                  iv
                }
              }
              id
            }
          }
          title {
            iv
          }
          autoOrder{
            iv
          }
          isListed{
            iv
          }
          body {
            iv
          }
        }
      }
    }
  `}
            >
              {({ loading, error, data }) => {

                if (loading) return (
                  <div className="centered">
                    <div >
                      <RotateLoader
                        color={"#16728a"}
                        loading={true}
                      />

                    </div>

                    {/* <div>Loading...</div> */}
                  </div>);
                else if (error) { return (<div>Error Connect!</div>) }
                else {

                  treeList = JSON.parse(JSON.stringify(data));
                  treeList.queryDocContents.sort((a, b) => (a.data.autoOrder == null || b.data.autoOrder == null || a.data.autoOrder.iv > b.data.autoOrder.iv) ? 1 : ((b.data.autoOrder.iv > a.data.autoOrder.iv) ? -1 : 0));
                  fullTreeList = JSON.parse(JSON.stringify(treeList));;

                  for (let i = 0; i < treeList.queryDocContents.length; i++) {
                    treeList.queryDocContents[i].children = [];
                    fullTreeList.queryDocContents[i].children = [];

                    if (treeList.queryDocContents[i].data.isListed && !treeList.queryDocContents[i].data.isListed.iv) {
                      treeList.queryDocContents.splice(i, 1);
                      i = i - 1;
                    }
                  }
                  var parentNode;
                  var childNode;

                  for (let i = 0; i < treeList.queryDocContents.length; i += 1) {

                    if (treeList.queryDocContents[i].data.parent != null) {
                      // console.log("treeList");
                      //console.log(treeList.queryDocContents[i].data.parent);
                      if (treeList.queryDocContents[i].data.parent.iv != null) {
                        for (let j = 0; j < treeList.queryDocContents[i].data.parent.iv.length; j += 1) {
                          parentNode = treeList.queryDocContents[i].data.parent.iv[j];
                          for (var k = 0; k < treeList.queryDocContents.length; k++) {
                            if (treeList.queryDocContents[k].id === parentNode.id) {
                              childNode = {}; childNode = treeList.queryDocContents[i]
                              treeList.queryDocContents[k].children.push(childNode);
                              treeList.queryDocContents[k].children.sort((a, b) => (a.data.autoOrder == null || b.data.autoOrder == null || a.data.autoOrder.iv > b.data.autoOrder.iv) ? 1 : ((b.data.autoOrder.iv > a.data.autoOrder.iv) ? -1 : 0));
                            }
                          }
                        }
                      }
                    }
                  }
                  if (treeList.queryDocContents != null) {
                    for (let i = 0; i < treeList.queryDocContents.length; i++) {
                      if (treeList.queryDocContents[i].data.parent != null && treeList.queryDocContents[i].data.parent.iv != null) {
                        if (treeList.queryDocContents[i].data.parent.iv.length > 0) {
                          treeList.queryDocContents.splice(i, 1);
                          i = i - 1;
                        }
                      }
                    }
                  }

                  //----
                  for (let i = 0; i < fullTreeList.queryDocContents.length; i += 1) {

                    if (fullTreeList.queryDocContents[i].data.parent !== null && fullTreeList.queryDocContents[i].data.parent.iv !== null) {
                      for (let j = 0; j < fullTreeList.queryDocContents[i].data.parent.iv.length; j += 1) {
                        parentNode = fullTreeList.queryDocContents[i].data.parent.iv[j];
                        for (let k = 0; k < fullTreeList.queryDocContents.length; k++) {
                          if (fullTreeList.queryDocContents[k].id === parentNode.id) {
                            childNode = {}; childNode = fullTreeList.queryDocContents[i];
                            fullTreeList.queryDocContents[k].children.push(childNode);
                            fullTreeList.queryDocContents[k].children.sort((a, b) => (a.data.autoOrder == null || b.data.autoOrder == null || a.data.autoOrder.iv > b.data.autoOrder.iv) ? 1 : ((b.data.autoOrder.iv > a.data.autoOrder.iv) ? -1 : 0));
                          }
                        }
                      }
                    }
                  }
                  for (let i = 0; i < fullTreeList.queryDocContents.length; i++) {
                    if (fullTreeList.queryDocContents[i].data.parent != null && fullTreeList.queryDocContents[i].data.parent.iv != null) {
                      if (fullTreeList.queryDocContents[i].data.parent.iv.length > 0) {
                        fullTreeList.queryDocContents.splice(i, 1);
                        i = i - 1;
                      }
                    }
                  }
                  //---
                  for (let i = 0; i < treeList.queryDocContents.length; i++) {
                    jsonFolderPathArrayObject = {
                      path: "",
                      id: "",
                    };
                    buildfolderPathsIsListed(treeList.queryDocContents[i], jsonFolderPathArrayObject)
                  }
                  for (let j = 0; j < fullTreeList.queryDocContents.length; j++) {
                    jsonFolderPathArrayObject = {
                      path: "",
                      id: "",
                    };
                    buildArrayPaths(fullTreeList.queryDocContents[j], jsonFolderPathArrayObject)
                  }

                }

                return (
                  <Router>
                    <Layout folderPathArrayIsListed={folderPathArrayIsListed} folderPathArray={folderPathArray} fullTreeList={fullTreeList} treeList={treeList} />
                  </Router>
                )
              }}
            </Query>
          </ApolloProvider>
        }
      </div>
    );
  }
}

export default APP;




ReactDOM.render(

  <APP />
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
