import React from 'react';
import config from '../../config'
import '../styles.css';
import { BrowserRouter as Router, Link, } from 'react-router-dom';
import Search from "../search";
import Sidebar from "../sidebar"
class Header extends React.Component {
    render() {
        function myFunction() {
            var x = document.getElementById("navbar");
            if (x.className === "topnav") {
                x.className += " responsive";
            } else {
                x.className = "topnav";
            }
        }
        const logoImg = require('../images/new-logo.png');

        const searchIndices = [
          { name: `gcms`, title: `Pages`, hitComp: `PageHit` }
        ]
        return (
            <div className={'navBarWrapper'}>
                <nav className={'navBarDefault'}>
                    <div className={'navBarHeader'}>
                        <Router>
                            <Link to={config.header.logoLink} className={'navBarBrand'}>
                                <img className={'img-responsive displayInline'} src={logoImg} alt={'logo'} />
                            </Link>
                        </Router>
                        <div className={"headerTitle displayInline"} dangerouslySetInnerHTML={{ __html: config.header.title }} />
                        <span onClick={myFunction} className={'navBarToggle'}>
                            <span className={'iconBar'}></span>
                            <span className={'iconBar'}></span>
                            <span className={'iconBar'}></span>
                        </span>
                    </div>

                    <div className={'searchWrapper hiddenMobile navBarUL'}>
              <div style={{float:'right',width:300}}>
              {config.header.search.enabled===true ? 
                  <Search collapse indices={searchIndices} />:null
              }
              
              </div>
              </div>

                    <div id="navbar" className={'topnav'}>
                        <div className={'visibleMobile'}>
                        <Sidebar handler = {this.props.handler}  fullTreeList={this.props.fullTreeList} treeList={this.props.treeList} />
                       
                        <hr/>

                        </div>
                        <ul className={'navBarUL navBarNav navBarULRight'}>
                            {config.header.links.map((link, key) => {
                                if (link.link !== '' && link.text !== '') {
                                    return (
                                        <li key={key}>
                                            <a className="sidebarLink" href={link.link} rel="noopener noreferrer" target="_blank"  dangerouslySetInnerHTML={{ __html: link.text }} />
                                        </li>
                                    );
                                }
                                else return null
                            })}

                        </ul>
                    </div>
                </nav>
            </div>

        );

    }
}

export default Header;