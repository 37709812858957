import qs from 'qs';
import axios from 'axios';
import config from './config'
export const getToken = () => {
  var postData = {
    "scope": config.authorization.scope,
    "client_secret": config.authorization.client_secret,
    "client_id": config.authorization.client_id,
    "grant_type": config.authorization.grant_type,
  };
  let axiosConfig = {
    headers: {
      'Content-Type': config.authorization.content_type,
    }
  };
  return axios.post(config.authorization.uri, qs.stringify(postData), axiosConfig)
}