import React from "react"
import { Highlight, Snippet } from "react-instantsearch-dom"
//import { Link } from 'react-router-dom';
import { Tags } from "styled-icons/fa-solid/Tags"

export const PageHit = clickHandler => ({ hit }) => {

  return(

<div className={'divTable'} style={{width:'100%'}} >
<div className={'divTableBody'}>
<div className={'divTableRow'}>
<div className={'divTableCell1'}>
<a href={hit.path} onClick={clickHandler}>
<Highlight  attribute="title" hit={hit} tagName="mark" />
</a>

</div>

<div className={'divTableCell2'}>

<a href={hit.path} onClick={clickHandler}>
 
  <small style={{color:'#22263a'}}>
     <Tags size="1em" />
 
     <Snippet css={{paddingLeft: '5px !important;'}} hit={hit} attribute="body"  tagName="mark"/>
     </small>
     </a>
</div>
</div>
</div>
</div>



  )
  }

