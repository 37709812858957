import React from 'react';
import { Query } from "react-apollo";
import gql from "graphql-tag";
import NextPrevious from '../next-previous/NextPrevious';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import config from '../../config';
import { Link } from 'react-router-dom';
import RotateLoader from "react-spinners/ClipLoader";
class MDXRuntimeTest extends React.Component {

  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this)
  }
  
  handler() {
    this.setState({
      someVar: 'some value'
    })   
    this.props.handler();
  }
  getLocation() 
  {
      var myLocation;    
      if (typeof (document) != 'undefined') {
        myLocation = document.location.pathname;
        myLocation = decodeURI(myLocation)
        return myLocation;
      }
  }
  
  getPathBreadcrumbsElement(indexOfElement,breadcrumb)
  {
    let pathOfElement="";
    for(let i=0;i<breadcrumb.length;i++)
     {
      pathOfElement=pathOfElement+"/"+breadcrumb[i]
      if(i===indexOfElement)
      break;
     }
     return pathOfElement;
  }
 

  render() {
    var showdown = require('showdown'),
    converter = new showdown.Converter();
    var documentID="";
    const myLocation= this.getLocation()
    const folderPathArray=this.props.folderPathArray;
    const folderPathArrayIsListed=this.props.folderPathArrayIsListed;
    const breadcrumb =(myLocation.substr(1)).split("/");
    for(var i=0;i<folderPathArray.length;i++)
    {
      if(myLocation===folderPathArray[i].path)
      {
        documentID=folderPathArray[i].id;
        break;
      }
    }


    const breed = `id eq '` + documentID + `'`;
    const GET_DOCS = gql`
        query docQuery ($id: String!){
          queryDocContents (filter: $id){
            id
            data{
              body{
                iv
              }
              title{
                iv
              }
              isListed{
                iv
              }
            }
          }
        }
`;
    return (
      <div>
        <Query query={GET_DOCS} variables={{ id: breed }} >
          {({ loading, error, data }) => {
            if (loading) return(

            <div className="centered">
            <div >
            <RotateLoader
              
              color={"#16728a"}
              loading={true}
            />
          </div>
          
          {/* <div class="loading-text">Loading..</div> */}
          </div>);
            else if (error) return `Error! ${error}`;
           else
           {
           
            return (
              
              <div >
              
              {(data !== null && data.queryDocContents.length > 0) ?
                (
                  <div>
                  <Breadcrumbs separator={config.breadcrumbs.separator} aria-label="breadcrumb">
                  <Link to={'/'}  onClick = {this.handler} color="inherit"  key={'/'}>
                  Home
                </Link>
                  {breadcrumb.map((item,index) => (
                    <Link to={this.getPathBreadcrumbsElement(index,breadcrumb)}  onClick = {this.handler} color="inherit" key={item}>
                   {item}
                  </Link>
                  
                  ))} 
                 </Breadcrumbs>
                <div className={'titleWrapper'}>
               
                  <h1 className={'title'}>
                    {data.queryDocContents[0].data.title.iv}
                  </h1>
                </div>
                <div className={'mainWrapper'} dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.queryDocContents[0].data.body.iv) }}>
                </div>
                <div className={'addPaddTopBottom'} >
                <NextPrevious handler={this.handler} folderPathArray={folderPathArrayIsListed} location={myLocation} />
              </div>
                </div>
                ):null
              }
            
              </div>
              
            );
            }
          }}
          
        </Query>
      </div>
    );
  }
}

export default MDXRuntimeTest;