import * as React from "react";
import { ThemeProvider as EmotionThemeProvider } from "emotion-theming";
import { default as defaultTheme } from "./theme";
import Header from './header/Header';
import './styles.css';

class ThemeProvider extends React.Component {
	// eslint-disable-next-line
	constructor(props)
	{
		super(props)
	}
	state = {  }
	render() { 
		return (
			<div>
				<Header handler = {this.props.handler}  fullTreeList={this.props.fullTreeList} treeList={this.props.treeList}/>
				<EmotionThemeProvider theme={{ ...defaultTheme }}>
					{this.props.children}
				</EmotionThemeProvider>
			</div>
		);
	}
}
 
export default ThemeProvider;

