import React from "react";
import styled from "@emotion/styled";
import ThemeProvider from "../themeProvider";
import Sidebar from "../sidebar";
import MDXRuntimeTest  from "../template/docs";
import RightSidebar from "../right-sidebar/rightSidebar";
import config from "../../config";
import AlgoliaSendData from '../../utils/algolia';


const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const Content = styled("main")`
  display: flex;
  flex-grow: 1;
  margin: 0px 88px;
  margin-top: 3rem;

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
    margin: 0 10px;
    margin-top: 3rem;
  }
`;

const MaxWidth = styled("div")`
  @media only screen and (max-width: 100rem) {
    width: 100%;
    position: relative;
  }
`;
const LeftSideBarWidth = styled("div")`
  width: 350px;
`;
const RightSideBarWidth = styled("div")`
  width: 224px;
`;

class Layout extends React.Component {
  constructor(props){
    super(props);
    this.handler = this.handler.bind(this);
   
  }
 
  handler() {
    var myLocation="";
    if (typeof (document) != 'undefined') {
      myLocation = document.location.pathname;
      myLocation = decodeURI(myLocation)
    }
    this.setState({myLocation: myLocation})
  

  }

  
  state = { myLocation: "" ,}

  componentWillMount() {
    this.handler();
  }
  
  render() {
  const  treeList=this.props.treeList;
  const fullTreeList=this.props.fullTreeList
  const folderPathArrayIsListed=this.props.folderPathArrayIsListed;
  const folderPathArray=this.props.folderPathArray;
  const myLocation=this.state.myLocation;
  

    return (
        <ThemeProvider handler = {this.handler}  fullTreeList={fullTreeList} treeList={treeList}>
        {config.header.search.buildIndex? <AlgoliaSendData treeList={treeList}  fullTreeList={fullTreeList}/>:null}
          <Wrapper>
            <LeftSideBarWidth className={"hiddenMobile"} >
              <Sidebar handler = {this.handler}  fullTreeList={fullTreeList} treeList={treeList} />
            </LeftSideBarWidth>
            <Content>
              <MaxWidth>
                <MDXRuntimeTest  handler = {this.handler}  folderPathArrayIsListed={folderPathArrayIsListed} folderPathArray={folderPathArray} fullTreeList={fullTreeList} myLocation={myLocation}></MDXRuntimeTest>
              </MaxWidth>
            </Content>
            <RightSideBarWidth className={"hiddenMobile"}>
            <RightSidebar  />
            </RightSideBarWidth>
            <div >
            </div>
          </Wrapper>
        </ThemeProvider>
    );
  }
}

export default Layout;