import React from "react";
import styled from "@emotion/styled";
import { ExternalLink } from "react-feather";
import { Link } from 'react-router-dom';
import config from '../../config';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

const Sidebar = styled('aside')`
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: fixed;
  padding-left: 0px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: sticky;
  top: 0;
  padding-right: 10px;
  background-color: rgba(241,222,250,0.15);
  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: 100vh;
  }
  @media (min-width: 767px) and (max-width:1023px)
  {
    padding-left: 0;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0px;
    background-color: #373751;
    background: #373751;
    height: auto;
  }
`;


const Divider = styled(props => (
  <li {...props} >
    <hr />
  </li>
))
  `
  list-style: none;
  padding: 0.5rem 0;

  hr {
    margin: 0;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #ede7f3;
  }
`;
class SidebarLayout extends React.Component {

  render() {
    var folderPath = "";
    const treeList = this.props.treeList;
    //  const fullTreeList=this.props.fullTreeList; //when login

    //---------------------------expand------------------------///

    var myLocation = "";
    if (typeof (document) != 'undefined') {
      myLocation = document.location.pathname;
      myLocation = decodeURI(myLocation)
    }
    myLocation = myLocation.substr(1)
    var tmpExpandArr = myLocation.split("/");
    var expandString = "";
    var expandedArray = [];
    for (var i = 0; i < tmpExpandArr.length; i++) {
      expandString = expandString + "/" + tmpExpandArr[i];
      expandedArray.push(expandString.substr(1))
    }

    //---------------------------expand------------------------///

    const renderTree = (item, folderPath) => (
      <TreeItem key={(folderPath + "/" + item.id).substr(1)} nodeId={(folderPath + "/" + item.data.title.iv).substr(1)}
        style={{
          paddingBottom: '6px',
          fontWeight: 0
        }}
        label={<Link to={item.data.title.iv !== 'index' ? folderPath = folderPath + "/" + item.data.title.iv : '/'}  >{item.data.title.iv}</Link>}
        onClick={this.props.handler}>
        {Array.isArray(item.children) ? item.children.map(item => renderTree(item, folderPath)) : null}
      </TreeItem>
    );
    return (
      <Sidebar id="style-1">
        <TreeView className={'sideBarUL'}
          expanded={expandedArray}
          selected={myLocation}
          defaultCollapseIcon={<ExpandMoreIcon style={{ fontSize: 25 }} />}
          defaultExpandIcon={<ChevronRightIcon style={{ fontSize: 25 }} />}
        >
          {treeList.queryDocContents.map(item => (
            config.sidebar.ignoreIndex && item.data.title.iv === 'index' ? null : renderTree(item, folderPath)
          ))}
          <Divider />
          {config.sidebar.links.map((link, key) => {
            if (link.link !== '' && link.text !== '') {
              return (
                <TreeItem key={key} nodeId={link.link}
                  label={<a href={link.link}>{link.text}</a>}
                  icon={<ExternalLink size={14} />}>
                </TreeItem>
              );
            }
            else return null;
          })}
          <Divider />
        </TreeView>
      </Sidebar>
    )
  }
}

export default SidebarLayout;
