import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
class NextPrevious extends React.Component {


  state = { }
  render() {
    const { folderPathArray ,location } = this.props;    
 
    var locationIndex=0;
    for(var i=0;i<folderPathArray.length;i++)
    {
      if(folderPathArray[i].path===location)
      {
        locationIndex=i;
        break;
      }
    }
    return (
      <div className={'nextPreviousWrapper'}>
        {locationIndex > 0 ? 
          (<Link onClick = {this.props.handler} to={folderPathArray[locationIndex - 1].path} className={'previousBtn'}>
            <div className={'leftArrow'}>
              <svg preserveAspectRatio="xMidYMid meet" height="1em" width="1em" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor" className="_13gjrqj"><g><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></g></svg>
            </div>
            <div className={'preRightWrapper'}>
              <div className={'smallContent'}>
                <span>Previous</span>
              </div>
              <div className={'nextPreviousTitle'}>
                <span>{folderPathArray[locationIndex - 1].path.split("/")[folderPathArray[locationIndex - 1].path.split("/").length-1]!==''?folderPathArray[locationIndex - 1].path.split("/")[folderPathArray[locationIndex - 1].path.split("/").length-1] : 'Home'}</span>
              </div>
            </div>
          </Link>) : null
        }
        {locationIndex < folderPathArray.length-1 ?
          (<Link  onClick = {this.props.handler} to={folderPathArray[locationIndex + 1].path} className={'nextBtn'}>
            <div className={'nextRightWrapper'}>
              <div className={'smallContent'}>
                <span>Next</span>
              </div>
              <div className={'nextPreviousTitle'}>
                <span>{folderPathArray[locationIndex + 1].path.split("/")[folderPathArray[locationIndex + 1].path.split("/").length-1]}</span>
              </div>
            </div>
            <div className={'rightArrow'}>
              <svg preserveAspectRatio="xMidYMid meet" height="1em" width="1em" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor" className="_13gjrqj"><g><line x1="5" y1="12" x2="19" y2="12"/><polyline points="12 5 19 12 12 19"/></g></svg>
            </div>
          </Link>) : null
        }
      </div>
    );
  }
}

export default NextPrevious;
