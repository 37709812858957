import React from "react";
import styled from "@emotion/styled";
import '../styles.css';
import config from '../../config';

const Sidebar = styled('aside')`
  width: 100%;
  background-color: #fff;
  border-right: 1px solid #ede7f3;
  height: 100vh;
  overflow: auto;
  position: fixed;
  padding-left: 24px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: sticky;
  top: 0;
  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`;

class SidebarLayout extends React.Component {
  
  componentDidMount() {
    this.htmlTableOfContents();
  }

  componentDidUpdate() {
    var e = document.getElementById("toc"); 
    var child = e.lastElementChild;  
    while (child) { 
        e.removeChild(child); 
        child = e.lastElementChild; 
    } 
       this.htmlTableOfContents();   
  }
 
  htmlTableOfContents (documentRefParam) {
    var documentRef = documentRefParam || document;
    var toc = documentRef.getElementById('toc');
    var headings = [].slice.call(documentRef.body.querySelectorAll(config.tableOfContents.headings));
    headings.shift();
    headings.forEach(function (heading, index) {
        var anchor = documentRef.createElement('a');
        anchor.setAttribute('name', 'toc' + index);
        anchor.setAttribute('id', 'toc' + index);
        var link = documentRef.createElement('a');
        link.setAttribute('href', '#toc' + index);
        link.textContent = heading.textContent;
        var div = documentRef.createElement('li');
        div.setAttribute('class', heading.tagName.toLowerCase());
        div.appendChild(link);
        toc.appendChild(div);
       
        heading.parentNode.insertBefore(anchor, heading);
    });
}
  render() {
    return (
      <Sidebar>
      <ul className={'rightSideBarUL'} >
        <li  className={'rightSideTitle'}>contents</li>
        <div id="toc" className="toc">
        </div>
      </ul>
    </Sidebar>
    );
  }
}
export default SidebarLayout;