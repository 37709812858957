import React from 'react';
import config from "../../src/config";


class AlgoliaSendData extends React.Component {
// eslint-disable-next-line
  constructor(props) {
    super(props)
  }
  render() {
    // eslint-disable-next-line
    var treeList = this.props.treeList;
    var fullTreeList = this.props.fullTreeList;
    var indexAlgoliaArray = [];
    var jsonIndexAlgoliaObject = {
      title: "",
      body: "",
      path: "",
      id: "",
    };
    const removeMd = require('remove-markdown');
    function buildIndexAlgoliaArray(item, jsonIndexAlgoliaObject) {
      jsonIndexAlgoliaObject = {
        title: item.data.title.iv,
        body: removeMd(item.data.body.iv),
        path: jsonIndexAlgoliaObject.path + "/" + item.data.title.iv,
        id: item.id,
      }
      indexAlgoliaArray.push(jsonIndexAlgoliaObject);
      if (Array.isArray(item.children)) {
        jsonIndexAlgoliaObject = {
          title: item.data.title.iv,
          body: item.data.body.iv,
          path: item.data.title.iv !== 'index' ? jsonIndexAlgoliaObject.path : '/',
          id: item.id,
        }
        item.children.map(item => buildIndexAlgoliaArray(item, jsonIndexAlgoliaObject))
      }
    }
    for (let j = 0; j < fullTreeList.queryDocContents.length; j++) {
      jsonIndexAlgoliaObject = {
        title: "",
        body: "",
        path: "",
        id: "",
      };
      buildIndexAlgoliaArray(fullTreeList.queryDocContents[j], jsonIndexAlgoliaObject)
    }
    const algoliasearch = require("algoliasearch")
    const client = algoliasearch(config.header.search.algoliaAppId, config.header.search.algoliaAdminKey)
    const index = client.initIndex("gcms");

    index.setSettings({
      attributesToSnippet: [
        `body:${config.header.search.numberOfBodyWords}`,
      ]
    });
    index.clearObjects().then(() => {
      index
        .saveObjects(indexAlgoliaArray, { autoGenerateObjectIDIfNotExist: true })
        .then(({ objectIDs }) => {
        });
    });
    return (
      <div></div>
    )
  }
}

export default AlgoliaSendData;
