const config = {
	"gcms":{
		"uri":"https://content.coremodels.io/api/content/coremodels-docs/graphql",
	} ,
	"authorization" :{
		"uri" : "https://content.coremodels.io/identity-server/connect/token",
		"scope" : "squidex-api",
		"client_secret" : "phxhgotznp1jkshev9twvoy8t8xnbxyhvznhznnfxmax",
		"client_id" : "62f3d882e275fa24466d9c34",
		"grant_type" : "client_credentials",
		"content_type" : "application/x-www-form-urlencoded",
	},
	"header": {
		"logo": "",
		"logoLink": "/",
		"title": "CoreModels Documentation",
		"links": [
			{ "text": "", "link": "" }
		],
		"search": {
			"enabled": true,
			"indexName": "coremodels_doc",
			"algoliaAppId": "FAUKHISPHJ",
			"algoliaSearchKey": "6cc7a5adc3e8dbc5ef25408bd3e0642e",
			"algoliaAdminKey": "df0247362c8412794da8535d3aeca308",
			"buildIndex":true,
			"numberOfBodyWords" :20,
		}
	},
	"sidebar": {
		"links": [
			{ "text": "CORE MODELS", "link": "https://coremodels.io" },
			{ "text": "API Documentation", "link": "https://apidocs.coremodels.io" },
		],
		"ignoreIndex": true,
	},

	"tableOfContents": {
		"headings": "h1",
	},
	"breadcrumbs":{
		"separator": "/",
	}
};

module.exports = config;
